import { IDataTableViewOptions } from '@x/common/data';
import { IMemberRowObject } from '@x/ecommerce/domain-client';
import { MemberFilterInput } from '@x/schemas/ecommerce';

export const MEMBER_TABLE_OPTIONS_VIEW: IDataTableViewOptions<
  IMemberRowObject,
  MemberFilterInput,
  any
> = {
  id: 'member_table',
  columns: [
    { id: 'id', title: 'ID', sortable: true },
    { id: 'user', title: 'Member' },
    { id: 'state', title: 'State', sortable: true },
    { id: 'status', title: 'Status' },
    { id: 'membership', title: 'Membership' },
    { id: 'channel', title: 'Channel' },
    { id: 'createdAt', title: 'Created' },
    { id: 'updatedAt', title: 'Updated' },
    { id: 'startDate', title: 'Start Date' },
    { id: 'pointBalance', title: 'Point Balance' },
  ],
  defaultColumns: [
    'id',
    'user',
    'state',
    'status',
    'membership',
    'channel',
    'startDate',
    'pointBalance',
  ],
  page: { index: 0, size: 100 },
  pageSizes: [20, 50, 100, 200, 1000],
  sort: {
    column: 'id',
    order: 'desc',
  },
  enableStorage: false,
};
