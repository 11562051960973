import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IMemberBulkUpdateDialogData,
  IMemberBulkUpdateDialogOptions,
  IMemberBulkUpdateDialogResult,
  MemberBulkUpdateDialogComponent,
} from '../components/member-bulk-update-dialog/member-bulk-update-dialog.component';
import {
  MemberDialogComponent,
  MemberDialogData,
  MemberDialogResult,
} from '../components/member-dialog/member-dialog.component';

@Injectable()
export class MemberDialogService {
  constructor(private dialogs: MatDialog) {}

  openMemberCreateDialog(memberId?: number) {
    return this.dialogs.open<MemberDialogComponent, MemberDialogData, MemberDialogResult>(
      MemberDialogComponent,
      {
        data: { memberId },
      },
    );
  }

  membershipBulkUpdateDialog(
    options: IMemberBulkUpdateDialogOptions,
    value?: string | number | null,
  ) {
    const data: IMemberBulkUpdateDialogData = {
      title: 'Autocomplete Option',
      cancelLabel: 'Cancel',
      okLabel: 'Ok',
      ...options,
    };

    return this.dialogs.open<
      MemberBulkUpdateDialogComponent,
      IMemberBulkUpdateDialogData,
      IMemberBulkUpdateDialogResult
    >(MemberBulkUpdateDialogComponent, { data, minWidth: '400px' });
  }
}
