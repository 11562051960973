<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <mat-label>Channel</mat-label>
  <x-data-autocomplete
    class="mb-1"
    [provider]="channelDataProvider"
    [formControl]="channelControl"
    [multiple]="false"
  ></x-data-autocomplete>
  <mat-label *ngIf="data.message">{{ data.message }}</mat-label>
  <x-data-autocomplete
    class="mb-1"
    [provider]="data.provider"
    [formControl]="control"
    [filter]="data.filter"
    [multiple]="data.multiple ?? false"
  ></x-data-autocomplete>
</div>
<div mat-dialog-actions align="end">
  <button
    mat-button
    [mat-dialog-close]="{ assign: false, value: control.value, channelId: channelControl.value }"
  >
    {{ data.cancelLabel }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="{ assign: true, value: control.value, channelId: channelControl.value }"
  >
    {{ data.okLabel }}
  </button>
</div>
