import { Component, Input, OnInit } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { IMemberRowObject, MemberService } from '@x/ecommerce/domain-client';
import { MemberFilterInput } from '@x/schemas/ecommerce';
import { MemberDialogService } from '../../services/member-dialog.service';

@Component({
  selector: 'x-member-table',
  templateUrl: './member-table.component.html',
  styleUrls: ['./member-table.component.scss'],
  host: {
    class: 'x-member-table',
  },
})
export class MemberTableComponent implements OnInit {
  @Input()
  view: DataTableView<IMemberRowObject, MemberFilterInput, any, number>;

  constructor(
    private memberDialogService: MemberDialogService,
    private memberService: MemberService,
  ) {}

  ngOnInit() {}

  editMember(id: number) {
    this.memberDialogService
      .openMemberCreateDialog(id)
      .afterClosed()
      .subscribe((result) => {
        if (!(result && result.membershipId && result.channelId && result.state)) return;
        this.view
          .mutateRow(id, () => {
            return this.memberService.update({
              id: id,
              state: result?.state,
            });
          })
          .subscribe();
        // this.datasource.refresh();
      });
  }
}
