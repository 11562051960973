<h2 mat-dialog-title>{{ verb }} Membership</h2>
<form
  mat-dialog-content
  [formGroup]="membershipForm"
  id="voucher_program_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>
          <fa-icon icon="signature"></fa-icon>
          Name
        </mat-label>
        <input
          matInput
          type="text"
          formControlName="name"
          required
          autocomplete="disabled"
          maxlength="60"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" class="mt-4">
        <mat-label>
          <fa-icon icon="list"></fa-icon>
          Select Channels
        </mat-label>
        <x-model-autocomplete-chips
          formControlName="channels"
          [dataSource]="channelDataSource"
          placeholder="Search channels..."
          [displayWith]="channelDataSource.displayFn"
        ></x-model-autocomplete-chips>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="voucher_program_form">
    {{ verb }}
  </button>
</div>
