import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MembershipService } from '@x/ecommerce/domain-client';
import {
  ChannelAutocompleteDataSource,
  ProductVariantAutocompleteDatasource,
} from '@x/ecommerce/domain-data';
import { CreateMembershipInput, ProductVariantObject } from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface MembershipDialogData {
  membershipId?: number;
}

export interface MembershipDialogResult {
  data?: { id: number };
}

@Component({
  selector: 'x-membership-create-dialog',
  templateUrl: './membership-dialog.component.html',
  styleUrls: ['./membership-dialog.component.scss'],
  providers: [ProductVariantAutocompleteDatasource],
})
export class MembershipDialogComponent implements OnInit {
  //unsubscribe
  private _destroy$ = new Subject<void>();

  membershipId: number;
  verb: string = 'Create';

  membershipForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    channels: new UntypedFormControl([], Validators.required),
  });

  constructor(
    public dialog: MatDialogRef<MembershipDialogComponent>,
    private membershipService: MembershipService,
    public channelDataSource: ChannelAutocompleteDataSource,
    @Inject(MAT_DIALOG_DATA) public data: MembershipDialogData,
  ) {}

  ngOnInit(): void {
    if (this.data?.membershipId) {
      this.membershipService
        .fetchDetail(this.data?.membershipId)
        .pipe(
          tap((option) => {
            this.membershipId = option.id;
            this.membershipForm.get('name')?.setValue(option.name);
            this.membershipForm.get('channels')?.setValue(option.channels);
          }),
        )
        .subscribe();
      this.verb = 'Update';
    }
  }

  submit() {
    this.membershipForm.updateValueAndValidity();

    if (this.membershipForm.invalid) return;

    const form = this.membershipForm.value;
    const channelIds = form.channels.map((variant: ProductVariantObject) => variant.id);

    const data: CreateMembershipInput = {
      name: form.name,
      channelIds: channelIds,
    };

    this.membershipService
      .create(data)
      .pipe(
        takeUntil(this._destroy$),
        tap((data) => this.dialog.close({ data })),
      )
      .subscribe();
  }
}
