import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MembershipDialogComponent,
  MembershipDialogData,
  MembershipDialogResult,
} from '../components/membership-dialog/membership-dialog.component';

@Injectable()
export class MembershipDialogService {
  constructor(private dialogs: MatDialog) {}

  openMembershipCreateDialog(membershipId?: number) {
    return this.dialogs.open<
      MembershipDialogComponent,
      MembershipDialogData,
      MembershipDialogResult
    >(MembershipDialogComponent, {
      data: { membershipId },
    });
  }
}
