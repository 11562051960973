<x-data-table [view]="view" [selectable]="false">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>
  <ng-template xDataColumnDef="user" let-row>
    <x-user-pill
      *ngIf="row.user"
      [user]="row.user"
      [matMenuTriggerFor]="rowUserMenu"
      [matMenuTriggerData]="{ $implicit: row.user }"
    ></x-user-pill>
  </ng-template>
  <ng-template xDataColumnDef="state" let-row>
    <x-pill [color]="row.state === 'ACTIVE' ? 'primary' : 'default'">{{ row.state }}</x-pill>
  </ng-template>
  <ng-template xDataColumnDef="status" let-row>
    <x-pill>
      <div>State: {{ row.intervalState }}</div>
      <div>
        {{ row.startDate | datetime: 'shortDate' }}
        <span *ngIf="row.expiryDate">-</span>
        {{ row.expiryDate | datetime: 'shortDate' }}
      </div>
    </x-pill>
  </ng-template>
  <ng-template xDataColumnDef="membership" let-row>
    {{ row.membership.name }}
  </ng-template>
  <ng-template xDataColumnDef="channel" let-row>
    {{ row.channel.name }}
  </ng-template>
  <ng-template xDataColumnDef="createdAt" let-row>
    {{ row.startDate | datetime }}
  </ng-template>
  <ng-template xDataColumnDef="updatedAt" let-row>
    {{ row.updatedAt }}
  </ng-template>
  <ng-template xDataColumnDef="startDate" let-row>
    {{ row.createdAt | datetime }}
  </ng-template>
  <ng-template xDataColumnDef="pointBalance" let-row>
    {{ row.updatedAt | datetime }}
  </ng-template>

  <button
    x-data-button
    *xDataAction="'edit'; let row; primary: true"
    label="Edit"
    icon="edit"
    (click)="editMember(row.id)"
  ></button>

  <mat-menu #rowUserMenu>
    <ng-template matMenuContent let-user>
      <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View Customer</a>
    </ng-template>
  </mat-menu>
</x-data-table>
