<h2 mat-dialog-title>{{ verb }} Member</h2>
<span *ngIf="!create" class="customer">
  <fa-icon icon="user"></fa-icon>
  Customer: {{ memberName }}
</span>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="member_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field *ngIf="create" appearance="fill" class="mt-4">
        <mat-label>
          <fa-icon icon="user"></fa-icon>
          Select Customer
        </mat-label>
        <!-- <x-model-autocomplete
          formControlName="user"
          [dataSource]="userDataSource"
          placeholder="Search Customers..."
          [displayWith]="userDataSource.displayFn"
        ></x-model-autocomplete> -->
        <x-data-autocomplete
          formControlName="userId"
          [provider]="Providers.UserItemCollectionProvider"
        ></x-data-autocomplete>
      </mat-form-field>

      <mat-form-field *ngIf="create" appearance="fill" class="mt-4">
        <mat-label>
          <fa-icon icon="id-card"></fa-icon>
          Select Membership
        </mat-label>
        <!-- <x-model-autocomplete
          formControlName="membership"
          [dataSource]="membershipDataSource"
          placeholder="Search memberships..."
          [displayWith]="membershipDataSource.displayFn"
        ></x-model-autocomplete> -->

        <x-data-autocomplete
          formControlName="membershipId"
          [provider]="Providers.MembershipItemCollectionProvider"
        ></x-data-autocomplete>
      </mat-form-field>

      <!--Channels-->
      <x-channel-select
        *ngIf="create"
        icon="globe-africa"
        label="Select Channel"
        formControlName="channelId"
      ></x-channel-select>

      <!--States-->
      <mat-form-field *ngIf="!create" class="mt-4">
        <mat-label>
          <fa-icon icon="clock"></fa-icon>
          State
        </mat-label>
        <mat-select required formControlName="state">
          <mat-option value="ACTIVE">Active</mat-option>
          <mat-option *ngIf="state !== 'NEW'" value="INACTIVE">Inactive</mat-option>
          <mat-option disabled="true" value="NEW">New</mat-option>
        </mat-select>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" mat-dialog-close>Cancel</button>
  <button mat-button type="submit" color="primary" form="member_form" (click)="submit()">
    {{ verb }}
  </button>
</div>

<!-- <h2 mat-dialog-title>{{ verb }} Member</h2>
<span *ngIf="!create" class="customer">
  <fa-icon icon="user"></fa-icon>
  Customer: {{ memberName }}
</span>
<form
  mat-dialog-content
  [formGroup]="memberForm"
  id="voucher_program_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field *ngIf="create" appearance="fill" class="mt-4">
        <mat-label>
          <fa-icon icon="user"></fa-icon>
          Select Customer
        </mat-label>
        <x-model-autocomplete
          formControlName="user"
          [dataSource]="userDataSource"
          placeholder="Search Customers..."
          [displayWith]="userDataSource.displayFn"
        ></x-model-autocomplete>
      </mat-form-field>

      <mat-form-field *ngIf="create" appearance="fill" class="mt-4">
        <mat-label>
          <fa-icon icon="id-card"></fa-icon>
          Select Membership
        </mat-label>
        <x-model-autocomplete
          formControlName="membership"
          [dataSource]="membershipDataSource"
          placeholder="Search memberships..."
          [displayWith]="membershipDataSource.displayFn"
        ></x-model-autocomplete>
      </mat-form-field>


      <x-channel-select
        *ngIf="create"
        icon="globe-africa"
        label="Select Channel"
        formControlName="channel"
      ></x-channel-select>


      <mat-form-field *ngIf="!create" class="mt-4">
        <mat-label>
          <fa-icon icon="clock"></fa-icon>
          State
        </mat-label>
        <mat-select required formControlName="state">
          <mat-option value="ACTIVE">Active</mat-option>
          <mat-option *ngIf="state !== 'NEW'" value="INACTIVE">Inactive</mat-option>
          <mat-option disabled="true" value="NEW">New</mat-option>
        </mat-select>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="member_form" (click)="submit()">
    {{ verb }}
  </button>
</div> -->
