import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MemberService } from '@x/ecommerce/domain-client';
import {
  ChannelItemCollectionProvider,
  MembershipItemCollectionProvider,
  UserItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { MemberState } from '@x/schemas/ecommerce';

export interface MemberDialogData {
  memberId?: number;
}

export interface MemberDialogResult {
  // data?: { id: number };
  userId: number;
  channelCode: string;
  channelId: number;
  membershipId: number;
  state: MemberState;
}

@Component({
  selector: 'x-member-dialog',
  templateUrl: './member-dialog.component.html',
  styleUrls: ['./member-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberDialogComponent implements OnInit {
  verb: string = 'Create';
  create: boolean = true;
  memberName: string = '';
  state: MemberState = MemberState.New;

  readonly Providers = {
    UserItemCollectionProvider,
    ChannelItemCollectionProvider,
    MembershipItemCollectionProvider,
  };

  formGroup = new FormGroup({
    userId: new FormControl<number | null>(null, [Validators.required]),
    membershipId: new FormControl<number | null>(null, [Validators.required]),
    state: new FormControl<string | null>(null),
    channelId: new FormControl<number | null>(null, [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: MemberDialogData,
    private dialogRef: MatDialogRef<MemberDialogComponent, MemberDialogResult>,
    private memberService: MemberService,
  ) {}

  ngOnInit(): void {
    if (this.data.memberId) {
      this.create = false;
      this.memberService.fetchDetail(this.data.memberId).subscribe((mem) => {
        console.log(mem);
        this.formGroup.reset({
          userId: mem.user.id,
          membershipId: mem.membership.id,
          channelId: mem.channel.id,
          state: mem.state,
        });
        this.memberName = mem.user.firstName;
        this.state = mem.state;
      });
      this.verb = 'Update';
    }
  }

  submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    this.dialogRef.close({ ...(this.formGroup.value as any) });
  }
}
