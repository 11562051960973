import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MemberState } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-member-state-filter-field',
  templateUrl: './member-state-filter-field.component.html',
  preserveWhitespaces: false,
  styles: ['mat-form-field { width: 100%; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberStateFilterFieldComponent implements ControlValueAccessor {
  private _onTouched: any = () => {};

  @Input()
  multiple: boolean = false;

  // states
  memberStates = Object.values(MemberState);

  control = new UntypedFormControl();

  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe((value) => {
      if (value && value.length > 0) {
        fn(value);
      } else fn(null);
    });
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(obj: any): void {
    if (!obj) return;
    this.control.patchValue(obj, { emitEvent: false });
  }
}
