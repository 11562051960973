<mat-form-field>
  <mat-label>
    <fa-icon icon="user" matPrefix></fa-icon>
    Filter Customer
  </mat-label>

  <x-model-autocomplete-chips
    [formControl]="control"
    [dataSource]="autoDataSource"
    [displayWith]="autoDataSource.displayFn"
    [transformer]="transformer"
    placeholder="Search customer..."
  ></x-model-autocomplete-chips>
</mat-form-field>
