<mat-form-field>
  <mat-label>
    <fa-icon icon="clock"></fa-icon>
    Filter States
  </mat-label>
  <mat-select [formControl]="control" [multiple]="multiple">
    <mat-option *ngFor="let state of memberStates" [value]="state">
      {{ 'member.state.' + state | l }}
    </mat-option>
  </mat-select>
</mat-form-field>
