import { ChangeDetectionStrategy, Component, Inject, Type } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ChannelItemCollectionProvider,
  MembershipItemCollectionProvider,
} from '@x/ecommerce/domain-data';

export interface IMemberBulkUpdateDialogData {
  title: string;
  message?: string;
  okLabel: string;
  cancelLabel: string;
  multiple?: boolean;
  provider: Type<MembershipItemCollectionProvider>;
  value?: string | number;
  filter?: any;
  channelId?: number;
}

export interface IMemberBulkUpdateDialogOptions {
  title?: string;
  message?: string;
  okLabel?: string;
  cancelLabel?: string;
  provider: Type<MembershipItemCollectionProvider>;
  value?: string | number;
  filter?: any;
  multiple?: boolean;
}

export interface IMemberBulkUpdateDialogResult {
  assign: boolean;
  value: string | number | null;
  channelId: number;
}

@Component({
  selector: 'x-member-bulk-update-dialog',
  templateUrl: 'member-bulk-update-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberBulkUpdateDialogComponent {
  control = new UntypedFormControl(this.data.value ?? null);
  channelControl = new UntypedFormControl(this.data.channelId ?? null);
  channelDataProvider = ChannelItemCollectionProvider;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IMemberBulkUpdateDialogData,
  ) {}
}
