import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocaleModule } from '@x/common/locale';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { MemberBulkUpdateDialogComponent } from './components/member-bulk-update-dialog/member-bulk-update-dialog.component';
import { MemberDialogComponent } from './components/member-dialog/member-dialog.component';
import { MemberMembershipFilterFieldComponent } from './components/member-index/member-membership-filter-field.component';
import { MemberStateFilterFieldComponent } from './components/member-index/member-state-filter-field.component';
import { MemberUserFilterFieldComponent } from './components/member-index/member-user-filter-field.component';
import { MemberTableComponent } from './components/member-table/member-table.component';
import { MembershipDialogComponent } from './components/membership-dialog/membership-dialog.component';
import { MemberDialogService } from './services/member-dialog.service';
import { MembershipDialogService } from './services/membership-dialog.service';

@NgModule({
  declarations: [
    MembershipDialogComponent,
    MemberStateFilterFieldComponent,
    MemberUserFilterFieldComponent,
    MemberMembershipFilterFieldComponent,
    MemberDialogComponent,
    MemberTableComponent,
    MemberBulkUpdateDialogComponent,
  ],
  imports: [
    LocaleModule,
    RouterModule,
    CommonModule,
    // DashboardCoreModule,
    // DashboardFormModule,
    // DashboardGridModule,
    // ChannelSharedModule,
    EcommerceAdminCoreModule,
  ],
  providers: [MembershipDialogService, MemberDialogService],
  exports: [
    MemberStateFilterFieldComponent,
    MemberUserFilterFieldComponent,
    MemberMembershipFilterFieldComponent,
    MemberDialogComponent,
    MemberTableComponent,
    MemberBulkUpdateDialogComponent,
  ],
})
export class MembershipsSharedModule {}
